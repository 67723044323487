.gallery {
  display: block;
  padding: 1em;
  margin: 2em auto;
  border-radius: 0.3rem;
  background-color: var(--light);
  border: 1px solid var(--secondary);
}

.gallery .button {
}

.gallery .button:hover,
.gallery .button:focus {
}

.gallery .button:disabled {
  background-color: #666;
}
