.toolbar {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  z-index: 5;
  display: flex;
  align-content: flex-start;
  justify-items: stretch;
  background-color: var(--main);
  font-size: 0.8em;
}
@media (min-width: 30em) {
  .toolbar {
    font-size: 1em;
  }
}
@media (min-width: 50em) and (min-height: 30em) {
  .toolbar {
    top: -1rem;
  }
}

.toolbar button,
.toolbar .button {
  display: block;
  line-height: 1.1;
  padding: 0.5em;
  font-size: 1em;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.4);
  border: none;
  color: var(--bgc);
  transition: background-color 0.2s;
  cursor: pointer;
}

.toolbar button:hover,
.toolbar .button:hover {
  background-color: rgba(255, 255, 255, 0.4);
  background-color: var(--secondary);
}
