.order-grid {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.order-grid__item {
  display: block;
  width: 25%;
  background-size: cover;
  background-position: center;
  cursor: move;
}

.order-grid__item::after {
  content: '';
  display: block;
  width: 100%;
  padding-bottom: 100%;
}
