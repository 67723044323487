.login__form {
  text-align: center;
  padding: 1em;
}

.login__form input,
.login__form button {
  /* font-size: 1em;
  padding: 0.2em 0.6em;
  border: 0.1em dashed fuchsia;
  border-radius: 1em; */
}
