.photo {
  display: block;
  margin: 2em auto;
  padding: 1rem;
  overflow: hidden;
  border-radius: 0.3rem;
  border: 1px solid;
  background-color: rgba(0, 0, 0, 0.02);
}

.photo--is-dragging {
  max-width: 20em;
  max-height: 60vh;
}
.photo--is-dragging .photo__details {
  display: none;
}
.photo--is-dragging .photo__preview__image {
  margin-bottom: -2em;
}

.photo--error {
  border-color: red;
  box-shadow: 0 0 4px red;
}

.photo__preview {
  display: block;
  max-width: 100%;
  margin: 0 0 1rem;
  position: relative;
}

.photo__preview--uploading::after {
  content: '⏳';
  position: absolute;
  right: 0;
  bottom: 0;
  animation: uploading-timer 2s infinite linear;
}

@keyframes uploading-timer {
  0%,
  5% {
    transform: rotate(0deg);
  }
  45%,
  55% {
    transform: rotate(180deg);
  }
  95%,
  100% {
    transform: rotate(360deg);
  }
}

.photo__preview__image {
  display: block;
  height: auto;
  max-width: 100%;
  max-width: calc(100% + 2em);
  margin: -1rem -1rem 0 -1rem;
}

.photo__preview__url,
.photo__preview__error {
  display: block;
  margin-left: -1rem;
  margin-right: -1rem;
  bottom: 0;
  padding: 0.3rem 1rem;
  word-wrap: break-word;
  font-size: 0.8em;
  line-height: 1.1;
}

.photo__preview__url {
  background-color: rgba(0, 0, 0, 0.2);
}

.photo__preview__error {
  background-color: red;
  color: white;
}

/* @media (min-width: 20em) {
  .photo__preview {
    width: 30%;
    margin: 0;
    float: left;
  }

  .photo__details {
    width: 70%;
    float: right;
    padding-left: 1rem;
  }
} */

.photo__label {
  display: block;
  text-align: left;
  cursor: pointer;
}

.photo__input {
  display: block;
  width: 100%;
  font-size: 1rem;
  padding: 0.5em;
  margin: 0 0 1rem;
  border: 1px solid #ccc;
  border-radius: 0.5em;
}

.photo-actions {
  margin-top: -2em;
  margin-bottom: 2em;
}
