.uploader {
  display: block;
  width: 100%;
  margin: 1em 0;
  padding: 10%;
  background: #fff;
  border: 2px dashed #c699f0;
  border-radius: 0.5em;
  text-align: center;
}

.uploader--fullscreen::before {
  content: '';
  position: fixed;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0;
  z-index: 10;
}

.uploader--fullscreen::after {
  content: 'Drop your photos here to upload them';
  display: flex;
  position: fixed;
  top: 2rem;
  left: 2rem;
  right: 2rem;
  bottom: 2rem;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  border: 6px dashed #c699f0;
  z-index: 11;
  font-weight: bold;
  color: var(--main);
}

.upload-zone {
  position: relative;
}

.upload-zone__input {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}
.uploader--fullscreen .upload-zone__input {
  position: fixed;
  z-index: 12;
}
