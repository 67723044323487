*,
*::before,
*::after {
  box-sizing: border-box;
}

button,
input,
textarea {
  font-family: inherit;
}
